import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import Box from "@material-ui/core/Box";
import AutocorpLogo from "~/assets/vectors/ac-logo.svg";

const useStyles = makeStyles({
    root: {
        width: "100%",
        minHeight: "100vh",
        backgroundColor: "rgb(0, 0, 0)",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        animation: "$pulse 3.5s linear infinite",
        color: "white",
    },
    logo: {
        height: "75vh",
        width: "75vw",
    },
    "@keyframes pulse": {
        "0%": {
            opacity: "1",
        },
        "50%": {
            opacity: ".85",
            backgroundColor: lighten("rgb(0, 0, 0)", .25),
        },
        "100%": {
            opacity: "1",
        },
    },
});

interface ISplashProps {
    Redirect?: ReactElement | boolean;
}

export const Splash: React.FC<ISplashProps> = ({
    Redirect = null,
}) => {
    const styles = useStyles();

    return (
        <Box className={styles.root}>
            <AutocorpLogo className={styles.logo} />
            {Redirect}
        </Box>
    );
};