import React, { useCallback } from "react";
import { createView, ViewComponent } from "~/model/view";
import SecurityRedirect from "~/components/SecurityRedirect";
import { GetSecurityRedirect } from "~/components/SecurityRedirect/types";

const IndexPage: ViewComponent = () => {
    const getRedirectPath = useCallback<GetSecurityRedirect>((user) => (
        user ? "/leads" : "/login"
    ), []);

    return <SecurityRedirect to={getRedirectPath} />;
};

export default createView(IndexPage, {
    title: "Home",
});